/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { SyntheticEvent, useState, useEffect } from 'react';
import _ from 'lodash';
import { Box, Grid, Stack, Button, Divider, Typography, IconButton } from '@mui/material';
import '../../../../../assets/style/sass/chatProfile.scss';
import { Close } from '@mui/icons-material';
import {
  ChatIcon,
  EditIcon,
  UserIcon,
  // ShareIcon,
  CalendarIcon,
  PlusCircleIcon
} from '../../../../../utils/Icons';
import { toast } from 'react-toastify';
import { API } from '../../../../../api';
import VideoSection from './VideoSection';
import { ChatStyle } from '../ChatStyling';
import { useNavigate } from 'react-router-dom';
import MyChip from '../../../components/MyChip';
import Upvotes from '../../../components/Upvotes';
import { navigateToChat } from '../../NavigateToChat';
import CallBookingModal from '../../CallBookingModal';
// import ChatWithMeDialog from '../ChatWithMeDialog';
import ChatAvatarComponent from '../ChatAvatarComponent';
import RoleTypeTag from '../../../components/RoleTypeTag';
import { getUserDetails } from '../../../../../utils/orgName';
import { GetSocialIcon } from '../../../components/SocialIcon';
import WorkAndEduDesign from '../../../components/WorkAndEduDesign';
import TextAndChipSection from '../../../components/TextAndChipSection';
import { getFirstName, showUpvoteIcon } from '../../../components/common';
import { formatBioFunction } from '../../../components/formatBioFunction';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { RequestMatch } from '../../Matches/components/RequestAndRejectMatch';
import ProfileDetailsLoader from '../../../../../Loader/ProfileDetailsLoader';
import { camelCaseToCapital } from '../../../../../utils/topicsMapping';
import { camelCaseToCapitalIndustries } from '../../../../../utils/industriesMapping';
// import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

interface IChatProfile {
  chatProfile: any;
  onCloseChatProfile: () => void;
  workHistory: any;
  educationDetails: any;
  setActiveChat?: any;
  parentComponent: string;
  digitalIntro?: boolean;
  callBookingBtn?: boolean;
  chatbtn?: boolean;
  userEdit?: boolean;
  loading?: boolean;
}

const ChatProfile: React.FC<IChatProfile> = ({
  chatProfile,
  onCloseChatProfile,
  workHistory,
  educationDetails,
  setActiveChat,
  parentComponent,
  digitalIntro = false,
  callBookingBtn = false,
  chatbtn = false,
  userEdit = false,
  loading
}) => {
  const bookedCallDetails: any = '';
  const activeUserId = _.get(chatProfile, 'mid', ''); // Auth id
  // request match related
  const requestMatchFlagValue = _.get(chatProfile, 'requestMatch', false);
  const roles = _.get(chatProfile, 'roles', []);
  const requestedId = _.get(chatProfile, '_id', '');
  const requestGroups = _.get(chatProfile, 'requestGrps', []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  // const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const communityMembers = useAppSelector((state) => state.getCommunityMembers.data);
  const loginUserId = useAppSelector((state) => _.get(state.userProfile.data, '_id', ''));
  const userData = useAppSelector((state) => state.userProfile.data);
  const senderComId = userData?.personal_details?.communicationId;
  const requestingId = loginUserId;
  const fullName = _.get(chatProfile, 'name', '');
  const firstName = getFirstName(fullName);
  const [allGroupsDetails, setAllGroupsDetails] = useState<any>();
  const classes = ChatStyle();
  const commId = chatProfile?.personal_details?.communicationId;
  const [upVoteTotalValue, setUpVoteTotalValue] = useState(0);

  /** Chat with me Popup open */
  // const [openChatWithMe, setOpenChatWithMe] = useState(false);
  // const handleClickChatWithMe = (flag: boolean) => setOpenChatWithMe(flag);

  /** Call Booking Popup open */
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [openRequestMatch, setOpenRequestMatch] = useState(false);
  const [requestMatchFlag, setRequestMatchFlag] = useState(false);

  /** Calendar modals */
  const handleCalendar = (flag: boolean) => setCalendarOpen(flag);
  const handleRequestMatch = (flag: boolean) => setOpenRequestMatch(flag);
  const requestMade = () => {
    setRequestMatchFlag(false);
  };

  const cat = chatProfile?.personal_details?.category || chatProfile?.id?.category;
  const setSocialData = chatProfile?.personal_details?.social;
  const { userId } = getUserDetails();
  const isUpvoteIconVisible = showUpvoteIcon(chatProfile);

  const handleNavigateToChat = (member: any, section: string) => {
    const user_id = section === 'programUsers' || section === 'Community' ? member.mid : member.userId;
    const listedInCommunity = communityMembers?.find((each) => each?.mid === user_id);

    let isInCommunity = false;
    if (listedInCommunity) {
      isInCommunity = true;
    }
    if (section === 'Community') {
      navigateToChat(member?.personal_details?.communicationId, isInCommunity, personalThreadsArr, navigate, dispatch);
    } else {
      navigateToChat(member?.id?.communicationId, isInCommunity, personalThreadsArr, navigate, dispatch);
    }
  };

  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>, displayName: string) => {
    event.currentTarget.src = `https://ui-avatars.com/api/?name=${displayName}`;
  };

  const fetchAllGroups = async (activeUserId: string) => {
    try {
      const response = await API.getAllGroupsByUserId(activeUserId);
      if (response.status === 200 && response.statusText === 'OK') {
        setAllGroupsDetails(_.get(response, 'data.data', []));
      } else {
        toast.error('Group details failed to load');
      }
    } catch (e) {
      toast.error('Group details failed to load');
    }
  };

  useEffect(() => {
    if (activeUserId) {
      fetchAllGroups(activeUserId);
    }
  }, [activeUserId]);

  useEffect(() => {}, [upVoteTotalValue, chatProfile]);
  useEffect(() => {
    setUpVoteTotalValue(chatProfile?.upVote);
  }, [chatProfile?.upVote]);

  useEffect(() => {
    if (requestMatchFlagValue) {
      setRequestMatchFlag(true);
    } else {
      setRequestMatchFlag(false);
    }
  }, [requestMatchFlagValue]);

  const handleAddUpvote = async (receivedVoteIds: any) => {
    const data = {
      userIdVoter: getUserDetails().userId,
      voteValue: 1,
      pageType: parentComponent === 'CommunityMember' ? 'Community Member profile' : 'Chat profile',
      participantsUser: [receivedVoteIds]
    };
    try {
      const response = await API.saveVotevalue(data, getUserDetails().location);
      if (response) {
        setUpVoteTotalValue(upVoteTotalValue + 1);
        toast.success('Thanks for the recognition');
      }
    } catch (e) {
      toast.error('Something went wrong, please try again');
    }
  };
  const isJoinCall = async (meetingId: string) => {
    const { orgId } = getUserDetails();
    try {
      const response = await API.rejoinCallUpdateFlag(
        getUserDetails().userId,
        meetingId,
        orgId,
        getUserDetails().location
      );
    } catch (e) {}
  };

  let selfProfileFlag = false;
  if (userId === chatProfile?.mid) {
    // member is visiting their function
    selfProfileFlag = true;
  }
  const topics = camelCaseToCapital(_.get(chatProfile, 'savedTopics', []));
  const industries = camelCaseToCapitalIndustries(_.get(chatProfile, 'savedIndustries', []));

  /** SideSmallProfile with out Call book and msg me btn */
  return (
    <>
      {parentComponent === 'sideSmallProfile' ? (
        loading ? (
          <ProfileDetailsLoader chatbtn={chatbtn} callBookingBtn={callBookingBtn} />
        ) : (
          <Box height="100%" className="chatProfileBox">
            <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
              <Typography variant="h5">Profile Details</Typography>
              <Close className="cursor-pointer" color="primary" onClick={onCloseChatProfile} />
            </Stack>
            <Divider />
            <Box height="calc(100% - 65px)" p={2} overflow="auto">
              <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={0.5} pb={1}>
                <Box display="flex" alignItems="flex-start" columnGap="5px">
                  <ChatAvatarComponent
                    height="48px"
                    width="48px"
                    image={_.get(chatProfile, 'personal_details.headshot', '') || _.get(chatProfile, 'id.headshot', '')}
                    firstLetter={chatProfile?.personal_details?.name.slice(0, 1)}
                  />
                  <Box>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="h5">
                        {_.get(chatProfile, 'personal_details.name', '')} {_.get(chatProfile, 'displayName', '')}
                      </Typography>

                      {isUpvoteIconVisible && (
                        <Box
                          ml={0.75}
                          className={!selfProfileFlag ? 'cursor-pointer' : ''}
                          onClick={!selfProfileFlag ? () => handleAddUpvote(chatProfile?.mid) : undefined}
                        >
                          <Upvotes likes={upVoteTotalValue} toolTip={selfProfileFlag ? 'true' : ''} />
                        </Box>
                      )}
                    </Stack>

                    <Box>{formatBioFunction(chatProfile)}</Box>
                    <Box mt={1}>
                      <RoleTypeTag category={cat} />
                    </Box>
                  </Box>
                </Box>
                {/* <IconButton size='small'>
                  <ShareIcon size={19} />
                </IconButton> */}
                {userEdit && (
                  <IconButton size="small">
                    <EditIcon size={18} />
                  </IconButton>
                )}
              </Stack>

              {/* Public profile Social handal */}
              {setSocialData &&
                Object.values(setSocialData).some((value) => value !== null && value !== undefined && value !== '') && (
                  <Box p={1}>
                    <Box className="ChatProfileSocialHandal">
                      <Box>
                        <GetSocialIcon data={setSocialData} location="publicProfile" />
                      </Box>
                      {userEdit && (
                        <IconButton size="small">
                          <EditIcon size={18} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                )}
              <Divider />

              <Box pt={2}>
                {/* Digital intro - Video and Photo */}
                {digitalIntro && _.get(chatProfile, 'digital_intro.videoUrl', '') && (
                  <Box mb={2}>
                    <Box className="PublicProfileEdit" pb={2}>
                      <Typography variant="h5">Digital Intro</Typography>
                      {userEdit && (
                        <IconButton size="small">
                          <EditIcon size={18} />
                        </IconButton>
                      )}
                    </Box>
                    {/* (DA-2053) This part is hiodden for some time due to poor image quality */}
                    {digitalIntro && _.get(chatProfile, 'digital_intro.videoUrl', '') !== '' && (
                      <Box className="ChatProfile-video-container">
                        <VideoSection
                          videoUrl={_.get(chatProfile, 'digital_intro.videoUrl', '')}
                          parentComponent="publicProfile"
                        />
                      </Box>
                    )}

                    {/* : digitalIntro &&
                        (_.get(chatProfile, 'digital_intro.videoUrl', '') === '' ||
                          _.get(chatProfile, 'digital_intro.videoUrl', '') === undefined) ? (
                        <img
                          src={ chatProfile?.personal_details?.headshot }
                          onError={(e) => addImageFallback(e, chatProfile?.personal_details?.firstName)}
                          alt="profile"
                        style={{
                          margin: 0,
                          width: '100%',
                          objectFit: 'cover',
                          aspectRatio: '1.8023',
                          borderRadius: '8px'
                        }}
                      />
                    ) : digitalIntro &&
                        _.get(chatProfile, 'digital_intro.videoUrl', '') !== '' ? (
                        <video
                        src={chatProfile?.digital_intro?.videoUrl}
                        key={chatProfile?._id}
                        controls
                        style={{
                          margin: 0,
                          width: '100%',
                          aspectRatio: '1.8023',
                          // height: '100%',
                          cursor: 'pointer',
                          objectFit: 'cover',
                          borderRadius: '8px'
                        }}
                      />
                    ) : (
                      <img
                        src={_.get(chatProfile, 'id.headshot', '')}
                        onError={(e) => addImageFallback(e, chatProfile?.personal_details?.firstName)}
                        alt="profile"
                        style={{
                          margin: 0,
                          width: '100%',
                          objectFit: 'cover',
                          aspectRatio: '1.8023',
                          borderRadius: '8px'
                        }} 
                      />
                    )} */}
                  </Box>
                )}

                {/* Call booking and msg btn */}
                {(chatbtn || callBookingBtn) && (
                  <Stack direction="column" spacing={1} pb={2}>
                    {chatbtn && (
                      <Button
                        variant="outlined"
                        className="bookCallBtn"
                        onClick={() => {
                          // setOpenChatWithMe(true)
                          handleNavigateToChat(chatProfile, 'Community');
                        }}
                      >
                        <ChatIcon size={21} />
                        Chat With Me
                      </Button>
                    )}

                    {callBookingBtn && (
                      <>
                        <Button variant="outlined" onClick={() => handleCalendar(true)} className="bookCallBtn">
                          <CalendarIcon size={21} />
                          Book a time
                        </Button>

                        {/* Program members Booking Modal */}
                        <CallBookingModal
                          handleCloseModal={() => handleCalendar(false)}
                          openFlag={isCalendarOpen}
                          chatProfileName={chatProfile?.personal_details?.name || chatProfile?.displayName}
                          threadParticipantsUsers={[chatProfile]}
                          chatType="Chats"
                          pageName="Community"
                          bookedCallDetails={bookedCallDetails}
                        />
                      </>
                    )}
                  </Stack>
                )}

                {/* I can help you with */}
                <TextAndChipSection
                  editing={userEdit}
                  chipType="outline"
                  param="IcanHelpYouWith"
                  placeName="publicProfile"
                  heading="I can help you with:"
                  data={_.sortBy([...topics, ...industries])}
                />
                <Divider />
                {/* Hobbies and interests */}
                <Box py={2}>
                  <Box className="PublicProfileEdit" pb={2}>
                    <Typography variant="h5">Hobbies and interests</Typography>
                    {userEdit && (
                      <IconButton size="small">
                        <EditIcon size={18} />
                      </IconButton>
                    )}
                  </Box>
                  <Box>
                    {chatProfile?.personal_details?.hobbies?.length ? (
                      <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                        {chatProfile?.personal_details?.hobbies?.map((each: any, index: any) => {
                          return (
                            <MyChip
                              key={index}
                              label={each}
                              backgroundColor="#FFFFFF"
                              color="#68717A"
                              border="1px solid #CED4DA"
                            />
                          );
                        })}
                      </Stack>
                    ) : (
                      <Typography>No Hobbies</Typography>
                    )}
                  </Box>
                </Box>
                <Divider />
                {/* Education and Skills */}
                <Box py={2}>
                  <Box className="PublicProfileEdit" pb={2}>
                    <Typography variant="h5">Education and Skills</Typography>
                    {userEdit && (
                      <IconButton size="small">
                        <PlusCircleIcon size={18} />
                      </IconButton>
                    )}
                  </Box>
                  <Box>
                    <WorkAndEduDesign
                      data={_.get(educationDetails, 'updatedData', [])}
                      wherePrint="chatProfile"
                      itemType="education"
                      onEdit={(item) => {}} // Handle edit action if needed
                    />
                  </Box>
                </Box>
                <Divider />
                {/* Work Experience */}
                <Box pt={2}>
                  <Box className="PublicProfileEdit" pb={2}>
                    <Typography variant="h5">Work Experience</Typography>
                    {userEdit && (
                      <IconButton size="small">
                        <PlusCircleIcon size={18} />
                      </IconButton>
                    )}
                  </Box>
                  <Box>
                    <WorkAndEduDesign
                      data={_.get(chatProfile, 'professional_details.workHistory')}
                      wherePrint="chatProfile"
                      onEdit={(item) => {}}
                      itemType="work" // Specify the itemType as 'work' for work history
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      ) : parentComponent === 'CommunityMember' ? (
        <>
          <Box>
            <Box className="ProfileBox">
              <Box className="CommunityProfileBox">
                <Box className="ProfileCard">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ChatAvatarComponent
                      height="96px"
                      width="96px"
                      fontSize="30px"
                      image={chatProfile?.personal_details?.headshot}
                      firstLetter={chatProfile?.personal_details?.name.slice(0, 1)}
                      loading={loading}
                    />
                  </Stack>
                  <Box>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="h1">{_.get(chatProfile, 'personal_details.name', '')}</Typography>
                      {isUpvoteIconVisible && (
                        <Box ml={0.75} className="cursor-pointer" onClick={() => handleAddUpvote(chatProfile?.mid)}>
                          <Upvotes likes={upVoteTotalValue} />
                        </Box>
                      )}
                    </Stack>
                    <Box my={0.5}>{formatBioFunction(chatProfile)}</Box>
                    <RoleTypeTag category={cat} />
                  </Box>
                </Box>

                <Box textAlign="right">
                  {/* <Box mb={.5}>
                    <IconButton>
                      <StarBorderOutlinedIcon />
                    </IconButton>
                  </Box> */}
                  <Box display="flex" alignItems="center" flexWrap="wrap" gap="8px">
                    <Button
                      variant="outlined"
                      className="bookCallBtn"
                      color="info"
                      onClick={() => {
                        // setOpenChatWithMe(true)
                        handleNavigateToChat(chatProfile, 'Community');
                      }}
                    >
                      <ChatIcon />
                      Chat With Me
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => handleCalendar(true)}
                      className="bookCallBtn"
                      color="info"
                    >
                      <CalendarIcon size={21} />
                      Book a time
                    </Button>
                    {requestMatchFlag && (
                      <Button
                        variant="contained"
                        onClick={() => handleRequestMatch(true)}
                        color="info"
                        className="requestMatchBtn"
                      >
                        <UserIcon width={14} height={18} />
                        Request a Match
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
              {/* (DA-2053) This part is hiodden for some time due to poor image quality */}
              <Divider />
              <Box p={2}>
                {chatProfile?.digital_intro?.videoUrl ? (
                  <Box className="CommunityUservideoArea">
                    <VideoSection videoUrl={chatProfile?.digital_intro?.videoUrl} parentComponent="Community" />
                  </Box>
                ) : (
                  <img
                    src={
                      chatProfile?.personal_details?.headshot
                        ? chatProfile?.personal_details?.headshot
                        : `https://ui-avatars.com/api/?name=${chatProfile?.personal_details?.firstName}`
                    }
                    onError={(e) => addImageFallback(e, chatProfile?.personal_details?.firstName)}
                    alt="profile"
                    className="ProfileImageVideoSize"
                  />
                )}
              </Box>
            </Box>

            {/* Bio */}
            {chatProfile?.personal_details?.bio ? (
              <Box my={{ xs: 1, sm: 2 }} className="ProfileBox">
                <Box p={2}>
                  <Typography variant="h5">Bio</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  {chatProfile?.personal_details?.bio ? (
                    <Typography whiteSpace="pre-line">{chatProfile?.personal_details?.bio}</Typography>
                  ) : (
                    <Typography>No Bio Feild</Typography>
                  )}
                </Box>
              </Box>
            ) : null}

            {/* I can help you with */}
            <Box mt={{ xs: 1, sm: 2 }} className={'ProfileBox'}>
              <TextAndChipSection
                divider
                chipType="outline"
                param="IcanHelpYouWith"
                heading="I can help you with:"
                data={_.sortBy([...topics, ...industries])}
              />
            </Box>

            {/* Work Experience */}
            <Box my={{ xs: 1, sm: 2 }} className="ProfileBox">
              <Box p={2}>
                <Typography variant="h5">Work Experience</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <WorkAndEduDesign
                  data={_.get(chatProfile, 'professional_details.workHistory')}
                  itemType="work" // Specify the itemType as 'work' for work history
                  wherePrint="communityUserProfile"
                  onEdit={(item) => {}} // Handle edit action if needed
                />
              </Box>
            </Box>

            {/* Education and Skills */}
            <Box my={{ xs: 1, sm: 2 }} className="ProfileBox">
              <Box p={2}>
                <Typography variant="h5">Education and Skills</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <WorkAndEduDesign
                  data={_.get(chatProfile, 'professional_details.education')}
                  itemType="education"
                  wherePrint="communityUserProfile"
                  onEdit={(item) => {}} // Handle edit action if needed
                />
              </Box>
            </Box>

            {/* Hobbies and interests */}
            <Box my={{ xs: 1, sm: 2 }} className="ProfileBox">
              <Box p={2}>
                <Typography variant="h5">Hobbies and interests</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                {chatProfile?.personal_details?.hobbies?.length ? (
                  <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                    {chatProfile?.personal_details?.hobbies?.map((each: any, index: any) => {
                      return <MyChip label={each} />;
                    })}
                  </Stack>
                ) : (
                  <Typography>No Hobbies</Typography>
                )}
              </Box>
            </Box>

            {/* Social handles */}
            <Box mt={{ xs: 1, sm: 2 }} className="ProfileBox">
              <Box p={2}>
                <Typography variant="h5">Social handles</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <GetSocialIcon data={setSocialData} />
              </Box>
            </Box>

            {/* My Programs */}
            <Box mt={{ xs: 1, sm: 2 }} className="ProfileBox">
              <Box p={2}>
                <Typography variant="h5">My Programs</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                  {_.isArray(chatProfile?.allGroupsUserBelongsTo) &&
                    !_.isEmpty(chatProfile?.allGroupsUserBelongsTo) &&
                    chatProfile?.allGroupsUserBelongsTo?.map((each: any, index: any) => (
                      <MyChip key={index} label={each} />
                    ))}
                  {_.isArray(chatProfile?.allGroupsUserBelongsTo) && _.isEmpty(chatProfile?.allGroupsUserBelongsTo) && (
                    <Typography>No Program Experience</Typography>
                  )}
                </Stack>
              </Box>
            </Box>
          </Box>

          {/* Community Booking Modal */}
          <CallBookingModal
            handleCloseModal={() => handleCalendar(false)}
            openFlag={isCalendarOpen}
            chatProfileName={chatProfile?.personal_details?.name}
            threadParticipantsUsers={[chatProfile]}
            chatType="Chats"
            pageName="Community"
            bookedCallDetails={bookedCallDetails}
          />

          {/* Request a Match Modal */}
          <RequestMatch
            openRequestMatch={openRequestMatch}
            handleRequestMatch={handleRequestMatch}
            requestingId={requestingId}
            requestedId={requestedId}
            roles={roles}
            communicationUserId={commId}
            senderComId={senderComId}
            requestGroups={requestGroups}
            requestMade={requestMade}
            receiverFirstName={firstName}
          />
        </>
      ) : (
        <Grid item xs={8} height="100%">
          <Box height="calc(100vh - 85px)" overflow="auto">
            <Box bgcolor="#FFFFFF" border="1px solid #EFF0F4" borderRadius="8px" margin="16px 16px 16px 0">
              <Box borderBottom="1px solid #EFF0F4" padding="20px">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center" columnGap="15px">
                    <Box display="flex" alignItems="center" columnGap="15px">
                      <Box
                        width="50px"
                        height="50px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                      >
                        <Box>
                          <ChatAvatarComponent
                            height="60px"
                            width="60px"
                            image={chatProfile?.personal_details?.headshot}
                            firstLetter={chatProfile?.personal_details?.name.slice(0, 1)}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box display="flex" alignItems="center" columnGap="15px">
                        <Typography variant="h1">{_.get(chatProfile, 'personal_details.name', '')}</Typography>
                        {/* <Upvotes likes={95} /> */}
                      </Box>
                      <Box display="flex" alignItems="center">
                        {formatBioFunction(chatProfile)}
                      </Box>
                      <RoleTypeTag category={cat} />
                    </Box>
                  </Box>

                  <Box textAlign="right">
                    {/* <Box mb={1}>
                      <img
                        src={starimg}
                        alt="starimg"
                        width="34px"
                        height="34px"
                      />
                    </Box> */}
                    <Box display="flex" alignItems="center" columnGap="10px">
                      <Box display="flex" alignItems="center">
                        <Button
                          className={classes.memberChatBttn}
                          onClick={() => {
                            handleNavigateToChat(chatProfile, 'Profile');
                          }}
                        >
                          <Box mr={1}>
                            <ChatIcon size={21} />
                          </Box>
                          Chat with me
                        </Button>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Button className={classes.memberChatBttn} onClick={() => handleCalendar(true)}>
                          <Box mr={1}>
                            <CalendarIcon size={19} />
                          </Box>
                          Book a time
                        </Button>
                      </Box>
                      {/* Booking Modal */}
                      <CallBookingModal
                        handleCloseModal={() => handleCalendar(false)}
                        openFlag={isCalendarOpen}
                        chatProfileName={chatProfile?.personal_details?.name}
                        threadParticipantsUsers={[chatProfile]}
                        chatType="Chats"
                        pageName="Community"
                        bookedCallDetails={bookedCallDetails}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box p={2} mb={2}>
                {chatProfile?.digital_intro?.videoUrl ? (
                  <video
                    src={chatProfile?.digital_intro?.videoUrl}
                    key={chatProfile?.digital_intro}
                    controls
                    className="chatProfileDigital_intro video"
                  />
                ) : (
                  <img
                    src={
                      chatProfile?.personal_details?.headshot
                        ? chatProfile?.personal_details?.headshot
                        : `https://ui-avatars.com/api/?name=${chatProfile?.personal_details?.firstName}`
                    }
                    onError={(e) => addImageFallback(e, chatProfile?.personal_details?.firstName)}
                    alt=""
                    className="chatProfileDigital_intro"
                  />
                )}
                <Typography variant="h5" mr={1} my={1.5}>
                  I can help you with:
                </Typography>
                <Box display="flex" alignItems="center" columnGap="10px">
                  <Typography>Not yet for now</Typography>
                  {/* <Button className={classes.memberHelpBtn}>
                  Weaknesses
                </Button>
                <Button className={classes.memberHelpBtn}>
                  Cover letter
                </Button>
                <Button className={classes.memberHelpBtn}>Resume</Button>

                <Button className={classes.memberHelpBtn}>Grit</Button>
                <Button className={classes.memberHelpBtn}>LinkedIn</Button>

                <Button className={classes.memberHelpBtn}>
                  Work experience
                </Button>
                <Button className={classes.memberHelpBtn}>Strengths</Button>
                <Button className={classes.memberHelpBtn}>
                  Interview skills
                </Button> */}
                </Box>
                <Typography variant="h5" mr={1} my={1.5}>
                  My Programs
                </Typography>
                <Box display="flex" alignItems="center" columnGap="10px">
                  Still Learning
                  {/* <Button className={classes.priorHelpBtn}>
                  First Year Students program 2021
                </Button>
                <Button className={classes.priorHelpBtn}>
                  First Year Students program 2022
                </Button>
                <Button className={classes.priorHelpBtn}>
                  First Year Students program 2023
                </Button> */}
                </Box>
              </Box>
            </Box>

            <Box className={classes.memberBoxSize}>
              <Typography variant="h5" mr={1} padding="16px">
                Work Experience
              </Typography>
              <Divider />

              {_.get(workHistory, 'updatedData')?.length ? (
                <Box p={2}>
                  {(Array.isArray(_.get(workHistory, 'updatedData')) ? _.get(workHistory, 'updatedData') : [])?.map(
                    (each: any, ind: any) => {
                      return (
                        <React.Fragment key={ind}>
                          <Box display="flex" alignItems="flex-start" pb={1}>
                            {/* <IconButton>
                              <img src={favicon} alt="favicon" width="25px" height="25px" />
                            </IconButton> */}

                            <Box width="100%">
                              <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
                                <Box mb={1}>
                                  <Box className={classes.membersNameText}>{each.company_name}</Box>
                                  <Box className={classes.memberWorkHistory}>{each.title}</Box>
                                </Box>
                                <Box>
                                  <Box className={classes.memberWorkHistory} mr="50px">
                                    {each.start_date} - {each.end_date ? each.end_date : 'Present'}
                                  </Box>
                                </Box>
                              </Box>

                              <Box className={classes.membersNameText} color="#0071A9" />
                              <Box className={classes.memberWorkHistory}>{each.industry}</Box>
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    }
                  )}
                </Box>
              ) : (
                <Box p={2}>No Work Experience</Box>
              )}
            </Box>

            <Box className={classes.memberBoxSize}>
              <Typography variant="h5" mr={1} padding="16px">
                Education and Skills
              </Typography>
              <Divider />
              {_.get(educationDetails, 'updatedData')?.length ? (
                <Box p={2}>
                  {(Array.isArray(_.get(educationDetails, 'updatedData'))
                    ? _.get(educationDetails, 'updatedData')
                    : []
                  )?.map((each: any, index: any) => {
                    return (
                      <React.Fragment key={index}>
                        <Box display="flex" alignItems="flex-start" columnGap="10px">
                          <Box>
                            <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                              <Box mb={1}>
                                <Box className={classes.membersNameText}>{each.university}</Box>
                                <Box className={classes.memberWorkHistory}>
                                  {each.major}, {each.minor}
                                </Box>
                                <Box className={classes.memberWorkHistory}>
                                  {new Date(each.graduation_date).getFullYear()}
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </Box>
              ) : (
                <Box p={2}>No Education Details</Box>
              )}
            </Box>

            <Box className={classes.memberBoxSize}>
              <Typography variant="h5" mr={1} padding="16px">
                Hobbies and interests
              </Typography>
              <Divider />
              {chatProfile?.personal_details?.hobbies?.length ? (
                <Box p={2} display="flex" columnGap={1} alignItems="center" flexWrap="wrap">
                  {(chatProfile?.personal_details?.hobbies ?? []).map((each: any, index: any) => {
                    return (
                      <MyChip
                        key={index}
                        label={each}
                        backgroundColor="#FFFFFF"
                        color="#68717A"
                        border="1px solid #CED4DA"
                      />
                    );
                  })}
                </Box>
              ) : (
                <Box p={2}>No Hobbies</Box>
              )}
            </Box>

            <Box className={classes.memberBoxSize}>
              <Typography variant="h5" mr={1} padding="16px">
                Social handles
              </Typography>
              <Divider />
              <GetSocialIcon data={setSocialData} />
            </Box>
          </Box>
        </Grid>
      )}

      {/* In side the 'ChatWithMeDialog' I design the Popup */}
      {/* <ChatWithMeDialog
        openChatWithMe={openChatWithMe}
        handleClickChatWithMe={handleClickChatWithMe}
      /> */}
    </>
  );
};
export default ChatProfile;
