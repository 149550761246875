/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-invalid-this */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  Autocomplete,
  DialogActions,
  DialogContent,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getUserDetails } from '../../../utils/orgName';
import { API } from '../../../api';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';
import { userBioCountUpdate, userBioUpdate, userInfoUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import countryCodeArray from '../../../constants/countrycode';
import { disabilityPopupActions } from '../../../redux/slices/disability/disabilityPopupSlice';
import { countries } from '../../profile-page/countryData';
import { timezones } from '../../profile-page/timeZones';
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Controller, SubmitHandler, FieldValues, useForm } from 'react-hook-form';
// import ChatAvatarComponent from "../tabs/chat/ChatAvatarComponent";
import { validatePhoneNumber } from '../../../constants/PhoneNumberValidator';
import PhoneInput from '../../../constants/PhoneInput';
import { fetchOrgDetails } from '../../../redux/slices/orgDetails/orgDetailsSlice';
import ROLES from '../../../utils/roles';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import useProfileDetails from '../../../hooks/useProfileDetails';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { IsAgileExecutive, categoryLabelFormatter, handleImageCompression, imageSizeLimit } from './common';
import groupRoleMappings from '../../../utils/groupRoleMappings';
import { AGILE_ORG } from '../../../utils/constants';
import { useAuth0 } from '@auth0/auth0-react';
import useOrgSettings from '../../../hooks/useOrgSettings';
import BirthdayField from './BirthdayField';

const imgSize = imageSizeLimit();

const EditProfileForm = () => {
  const sortedCountries = [...countries]?.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });
  const data = useAppSelector((state) => state.userProfile.data?.personal_details) || {
    firstName: getUserDetails().firstName,
    lastName: getUserDetails().lastName,
    gender: '',
    country: '',
    phoneNumber: '',
    ethnicity: '',
    timezone: '',
    headshot: getUserDetails().picture,
    firstGenStudent: 'No',
    email: getUserDetails().email,
    category: '',
    dob: '',
    phonePrefix: '',
    ftue: false,
    bio: ''
  };
  const programRole = ['Options'];
  const { ftue } = useProfileDetails();
  const userData = useAppSelector((state) => state.userProfile.data);
  const userProfile = useAppSelector((state) => state.userProfile.data?.personal_details);
  const userBioTextCount = useAppSelector((state) => state.userProfile?.bioTextCount);
  const disability = useAppSelector((state) => state.userProfile.data?.personal_details?.disability) || [];
  const optionalFields = _.get(userData, 'settings', {
    gender: false,
    ethnicity: false,
    firstGenerationStudent: false,
    disabilityType: false,
    dob: false,
    country: false,
    category: false,
    timezone: false,
    workHistory: false,
    educationHistory: false,
    hobbiesInterests: false
  });
  interface CountryType {
    name: string;
    dial_code: string;
    code: string;
  }
  interface OptionalFields {
    country: boolean;
    gender: boolean;
    ethnicity: boolean;
    firstGenerationStudent: boolean;
    category: boolean;
    timezone: boolean;
    workHistory: boolean;
    educationHistory: boolean;
    hobbiesInterests: boolean;
  }

  const userTypes = useAppSelector((state) => state?.orgDetails?.data?.userTypes) || [];
  // default timezone from orgId
  const isAgileExec = IsAgileExecutive();
  let defaultTz = '';
  if (isAgileExec) {
    const groupRoleObject = groupRoleMappings[AGILE_ORG];
    defaultTz = groupRoleObject['defaultTimezone'];
  }
  // console.log(defaultTz, 'default timezone');

  function createRequiredTest(fieldName: keyof OptionalFields, errorMessage: string) {
    return function (this: yup.TestContext, value: string | undefined | null) {
      if ((optionalFields as OptionalFields)[fieldName]) {
        if (value) {
          return true; // the field is valid
        } else {
          // the field is invalid, so return a ValidationError object with an error message
          return this.createError({ message: errorMessage });
        }
      } else {
        return true; // the field is not required, so it's always valid
      }
    };
  }

  const schema = yup.object({
    firstName: yup
      .string()
      .required('First name is required')
      .test('no-empty-spaces', 'First name cannot be all empty spaces', (value) => {
        if (value) {
          return !/^[\s]+$/.test(value);
        }
        return true;
      })
      .min(1, 'Name must be at least 1 character')
      .max(25, 'Maximum 25 characters'),
    lastName: yup
      .string()
      .required('Last name is required')
      .test('no-empty-spaces', 'Last name cannot be all empty spaces', (value) => {
        if (value) {
          return !/^[\s]+$/.test(value);
        }
        return true;
      })
      .min(1, 'Name must be at least 1 character')
      .max(25, 'Maximum 25 characters'),

    phoneNumber: yup
      .string()
      // .required('Phone number is required')
      .test('is-valid-phone-number', 'Invalid phone number', async function (value) {
        if (value) {
          const { path, createError } = this;
          const validationResult = await validatePhoneNumber(value, selectedCountry);
          if (validationResult) {
            const { isValid, error } = validationResult;
            return isValid || createError({ path, message: error });
          }
          return createError({ path, message: 'Validation error' });
        }
        return true;
      }),

    timezone: yup
      .string()
      .test('is-required', 'Timezone is required', createRequiredTest('timezone', 'Timezone is required')),
    category: yup
      .string()
      .nullable()
      .test('is-required', 'Member type is required', createRequiredTest('category', 'Member type is required')),
    dob: yup
      .date()
      .nullable()
      .max(new Date(), 'Date of birth can not be in future')
      .transform((value, originalValue) => {
        if (originalValue === '') {
          return null;
        }
        return value;
      })
      .test('is-required', 'Date of birth is required', function (value) {
        if (optionalFields.dob) {
          if (value) {
            return true;
          } else {
            return this.createError({ message: 'Date of birth is required' });
          }
        } else {
          return true; // the field is not required, so it's always valid
        }
      })
      .typeError('Invalid date format'), // Specify type error message

    country: yup
      .string()
      .test('is-required', 'Country is required', createRequiredTest('country', 'Country is required')),
    gender: yup.string().test('is-required', 'Gender is required', createRequiredTest('gender', 'Gender is required')),
    ethnicity: yup
      .string()
      .test('is-required', 'Ethnicity is required', createRequiredTest('ethnicity', 'Ethnicity is required')),
    firstGenStudent: yup
      .string()
      .test(
        'is-required',
        'First generation student is required',
        createRequiredTest('firstGenerationStudent', 'First generation student is required')
      )
    // bio: yup.string().max(5, 'Bio must be at most 2000 characters').optional()
    // headshot: yup
    //   .mixed()
    //   .test('required', 'profile image is required', (value) => value.length > 0)
    //   .test('fileSize', 'File Size is larger than 10 MB', (value) => {
    //     return value.length && value[0].size <= 10485760; // 10 MB in bytes;
    //   })
    //   .test('fileType', 'Unsupported File Format', (value) => {
    //     return (
    //       value.length && ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml', 'image/gif'].includes(value[0].type)
    //     );
    //   })
  });

  const [loader, setLoader] = useState(false);
  const [headshot, setHeadshot] = useState<any>('');
  const [headshotErr, setHeadshotErr] = useState('');
  const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  // const [showCropper, setShowCropper] = useState(false);

  const selectedCountryObject: CountryType | undefined = countryCodeArray.find(
    (country) => country.dial_code === data.phonePrefix
  );

  const [selectedCountry, setSelectedCountry] = React.useState<CountryType | null>(selectedCountryObject || null);

  useEffect(() => {
    setSelectedCountry(() => selectedCountryObject || null);
  }, []);

  const handleCountryChange = (value: CountryType | null) => {
    setSelectedCountry(value);
  };

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });
  const loginUserData = useAppSelector((state) => state?.userProfile?.data) || [];
  console.log(errors);

  useEffect(() => {
    if (ftue) {
      const localData = localStorage.getItem('profileData');
      const userData = localData ? JSON.parse(localData) : data;
      setHeadshot(userData.headshot);
    } else {
      setHeadshot(data?.headshot);
    }
  }, []);

  useEffect(() => {
    if (ftue) {
      const localData = localStorage.getItem('profileData');
      const userData = localData ? JSON.parse(localData) : data;
      setHeadshot(userData.headshot);
      reset({
        ...userData,
        category: userTypes.includes(data.category) ? data.category : null
      });
    } else {
      reset({
        ...data,
        category: userTypes.includes(data.category) ? data.category : null
      });
    }
  }, [data]);

  useEffect(() => {
    const role = localStorage.getItem('role') || '';
    if (role !== ROLES.platAdmin) {
      dispatch(fetchOrgDetails(getUserDetails().orgId));
    }
  }, []);

  useEffect(() => {
    return;
  }, []);

  const userBioData = useAppSelector((state) => state.userProfile.data?.personal_details?.bio);

  const [bioText, setBioText] = useState(userBioData);
  const prevBioText = useRef(bioText);

  const handleUpdateUserBio = async () => {
    if (bioText !== prevBioText.current || bioText === '') {
      const idTokenClaims = await getIdTokenClaims();
      const accessToken = await getAccessTokenSilently();
      const { orgId } = getUserDetails();

      const tokens = {
        access_token: accessToken,
        id_token: _.get(idTokenClaims, '__raw', '')
      };

      const data = {
        bio: bioText
      };

      try {
        const response = await API.updateBio({ data, orgId, tokens });
        if (response.status === 200) {
          dispatch(userBioUpdate(response.data?.bio));
          dispatch(userBioCountUpdate(bioTextCount));
        }
      } catch {
        toast.error('Failed to update Member bio');
      }

      prevBioText.current = bioText;
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    handleUpdateUserBio();
    if (!headshot) {
      setHeadshotErr('Profile image is required.');
      return;
    }
    if (ftue && isOrgSettings) {
      dispatch(popupCountActions.increment(0));
    }
    localStorage.setItem(
      'profileData',
      JSON.stringify({ ...data, headshot: typeof headshot === 'string' ? headshot : URL.createObjectURL(headshot) })
    );
    let { firstName, lastName, phoneNumber, category, firstGenStudent, timezone, gender, country, ethnicity } = data;
    if (category === null) {
      category = '';
    }
    const submitFormData = new FormData();
    const date = data.dob ? moment(data.dob).format('MM-DD') : '';

    setLoader(true);
    const dataWithId: any = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phonePrefix: phoneNumber ? selectedCountry?.dial_code || _.get(loginUserData, 'phonePrefix', '') : '',
      phoneNumber,
      category,
      firstGenStudent,
      timezone,
      gender,
      country,
      image: headshot || _.get(loginUserData, 'headshot', ''),
      dob: date,
      ethnicity
      // bio
    };
    // const phoneNumberValidationResult = await validatePhoneNumber(data.phoneNumber, selectedCountry);
    // if (phoneNumberValidationResult && !phoneNumberValidationResult.isValid) {
    //   // Handle invalid phone number...
    //   return;
    // }
    const newData: any = {};
    for (let i in dataWithId) {
      if (i === 'disability') {
        newData[i] = JSON.stringify(dataWithId[i]?.join(','));
      } else {
        newData[i] = dataWithId[i];
      }
    }
    for (let i in newData) {
      submitFormData?.append(i, newData[i]);
    }
    try {
      const response = await API.updateUserProfile({
        orgId: getUserDetails().orgId,
        data: submitFormData
      });
      if (response.status === 200 && response.statusText === 'OK') {
        dispatch(userInfoUpdate(response.data));
        setLoader(false);
        dispatch(popupActions.updatePopups({ profile: { open: false, disable: false } }));
        // disabled for now disability form

        if (!ftue && optionalFields.hobbiesInterests && userProfile?.hobbies.length === 0) {
          dispatch(
            popupActions.updatePopups({
              hobbies: true,
              hobbiesDisable: true
            })
          );
        }
        localStorage.removeItem('profileData');
      }
    } catch (e: any) {
      setLoader(false);
      toast.error(_.get(e, 'response.data.message', 'Something went wrong, please try again'));
    }
  };

  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  // const { onChange, ...params } = register('headshot');

  const handlePhotoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event?.target?.files ? event?.target?.files[0] : null;

    if (!selectedFile) {
      // setHeadshotErr('Profile image is required. Please choose an image.');
      return;
    }
    if (selectedFile && selectedFile.size > imgSize.limitValue) {
      setHeadshotErr('The max file size is 10MB');
      return;
    }
    try {
      const photo = await handleImageCompression(selectedFile);

      if (photo && !['image/jpeg', 'image/png'].includes(photo.type)) {
        setHeadshotErr('Invalid file type. Only JPG, PNG files are allowed.');
      } else {
        setHeadshot(photo);
        setHeadshotErr('');
      }
    } catch (error) {
      console.error('Error handling image compression:', error);
    }
    // if (selectedFile) {
    //   setHeadshot(selectedFile);
    //   setShowCropper(true);
    // }
  };

  // const handleCropDone = (croppedArea: any) => { setShowCropper(false); };
  // const handleCropCancel = () => {
  //   setShowCropper(false);
  //   setHeadshot(null);
  // };

  const genderArray = [
    { name: 'Prefer not to say', value: 'Prefer not to say' },
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
    { name: 'Non-binary', value: 'Non-binary' }
  ];

  const ethnicityArray = [
    { label: 'Prefer not to say', value: 'Prefer not to say' },
    {
      label: 'American Indian or Alaska Native',
      value: 'American Indian or Alaska Native'
    },
    { label: 'Asian', value: 'Asian' },
    { label: 'Black or African American', value: 'Black or African American' },
    { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
    {
      label: 'Native Hawaiian or Other Pacific Islander',
      value: 'Native Hawaiian or Other Pacific Islander'
    },
    { label: 'White', value: 'White' }
  ];

  const getDialogActions = () => {
    return (
      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          loading={(!ftue || !isOrgSettings) && loader}
          id="personalInfoSubmit"
        >
          {ftue ? 'Next' : 'Save'}
        </LoadingButton>
      </DialogActions>
    );
  };

  interface FieldTypes {
    fieldName: string;
    component: ReactNode;
  }

  const inputsArray: FieldTypes[] = [
    {
      fieldName: 'gender',
      component: (
        <Grid item xs={12} sm={6}>
          <InputLabel>Gender {optionalFields.gender && '*'}</InputLabel>
          <FormControl fullWidth>
            <Controller
              name="gender"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    size="small"
                    fullWidth
                    id="gender"
                    options={genderArray}
                    getOptionLabel={(option) => option.name}
                    onChange={(_, option) => onChange(option ? option.value : '')}
                    value={genderArray.find((option) => option.value === value) || null}
                    autoHighlight
                    renderOption={(props, option) => {
                      return (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="Choose Gender"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'gender'
                        }}
                      />
                    )}
                  />
                );
              }}
            />
            <FormHelperText error>{getError('gender')?.toString()}</FormHelperText>
          </FormControl>
        </Grid>
      )
    },
    {
      fieldName: 'dob',
      component: <BirthdayField control={control} optionalFields={optionalFields} getError={getError} />
    },
    {
      fieldName: 'country',
      component: (
        <Grid item xs={12} sm={6}>
          <InputLabel>Country {optionalFields.country && '*'}</InputLabel>
          <FormControl fullWidth>
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    // autoComplete="off"
                    fullWidth
                    size="small"
                    id="country"
                    options={sortedCountries}
                    getOptionLabel={(option) => option?.label}
                    onChange={(_, option) => onChange(option ? option.code : '')}
                    filterOptions={(options, state) =>
                      options.filter((option) => option.label.toLowerCase().startsWith(state.inputValue.toLowerCase()))
                    }
                    value={sortedCountries?.find((option) => option?.code === value) || null}
                    autoHighlight
                    renderOption={(props, option) => {
                      return (
                        <Box component="li" {...props}>
                          {option.label}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="Choose Country"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'country'
                        }}
                      />
                    )}
                  />
                );
              }}
            />
            <FormHelperText error>{getError('country')?.toString()}</FormHelperText>
          </FormControl>
        </Grid>
      )
    },
    {
      fieldName: 'ethnicity',
      component: (
        <Grid item xs={12} sm={6}>
          <InputLabel>Ethnicity {optionalFields.ethnicity && '*'}</InputLabel>
          <FormControl fullWidth>
            <Controller
              name="ethnicity"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="ethnicity"
                    options={
                      [
                        ethnicityArray[0],
                        ...ethnicityArray.slice(1)?.sort((a: any, b: any) => {
                          const labelA = a.label.toLowerCase();
                          const labelB = b.label.toLowerCase();
                          if (labelA < labelB) {
                            return -1;
                          }
                          if (labelA > labelB) {
                            return 1;
                          }
                          return 0;
                        })
                      ] || []
                    }
                    filterOptions={(options, state) =>
                      options.filter((option) => option.label.toLowerCase().startsWith(state.inputValue.toLowerCase()))
                    }
                    getOptionLabel={(option) => option.label}
                    onChange={(_, option) => onChange(option ? option.value : '')}
                    value={ethnicityArray?.find((option) => option.value === value) || null}
                    autoHighlight
                    renderOption={(props, option) => {
                      return (
                        <Box component="li" {...props}>
                          {option.label}
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="Choose Ethnicity"
                        autoComplete="false"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'ethnicity'
                        }}
                      />
                    )}
                  />
                );
              }}
            />
            <FormHelperText error>{getError('ethnicity')?.toString()}</FormHelperText>
          </FormControl>
        </Grid>
      )
    },
    {
      fieldName: 'firstGenerationStudent',
      component: (
        <Grid item xs={12} sm={6}>
          <InputLabel>First generation student {optionalFields.firstGenerationStudent && '*'}</InputLabel>
          <FormControl fullWidth>
            <Controller
              control={control}
              name="firstGenStudent"
              render={({ field: { onChange, value } }) => (
                <Select
                  name="firstGenStudent"
                  value={value || ''}
                  id="firstGenStudent"
                  onChange={onChange}
                  fullWidth
                  required
                  error={checkError('firstGenStudent')}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return <Typography color={'#DCE0E4'}>Choose an option</Typography>;
                    }
                    return selected;
                  }}
                >
                  {/* <MenuItem value="">Select</MenuItem> */}
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              )}
            />
            <FormHelperText error>{getError('firstGenStudent')?.toString()}</FormHelperText>
          </FormControl>
        </Grid>
      )
    }
  ];

  const isOrgSettings = useOrgSettings();
  const mandatoryFields: FieldTypes[] = [];
  const includedFields: FieldTypes[] = [];
  const optionalFieldsArray: FieldTypes[] = [];

  for (let i in inputsArray) {
    const { fieldName } = inputsArray[i];
    const fieldStatus = (optionalFields as any)[fieldName];

    if (fieldStatus !== undefined) {
      if (fieldStatus) {
        mandatoryFields.push(inputsArray[i]);
      } else {
        includedFields.push(inputsArray[i]);
      }
    } else {
      optionalFieldsArray.push(inputsArray[i]);
    }
  }

  const [bioTextCount, setBioTextCount] = useState(userBioTextCount);

  return (
    <>
      <DialogContent>
        {/* Profile image */}
        <Box display="flex" gap={2} alignItems="center">
          <Box className="profileImageBox" position="relative" border={headshotErr ? '1px solid #DC3545' : ''}>
            <label htmlFor="profile-img" className="cursor-pointer">
              <Box className={headshot ? 'ProfileCameraBgOverlay' : 'EdirProfileCameraIcon'}>
                <CameraAltOutlinedIcon
                  className="CameraIcon"
                  sx={{ color: headshot ? '#FFFFFF' : headshotErr ? '#DC3545' : '#68717A' }}
                />
              </Box>
              {headshot && (
                <img
                  className="profileImage"
                  src={typeof headshot === 'string' ? headshot : URL.createObjectURL(headshot)}
                  alt="Group image"
                />
              )}
            </label>

            <input
              name="headshot"
              accept=".png, .jpeg, .jpg"
              type="file"
              onChange={handlePhotoChange}
              id="profile-img"
              className="img-input-profile-page"
            />
            {/* {showCropper && (
              <ImageCropper
                image={typeof headshot === 'string' ? headshot : URL.createObjectURL(headshot as Blob)}
                onCropDone={handleCropDone}
                onCropCancel={handleCropCancel}
                open={showCropper}  // Pass the state to control the dialog
             />
            )} */}
          </Box>
          <Box>
            <Box display="flex" alignItems="center" gap="8px">
              <Typography variant="h6">Profile image *</Typography>
              <Typography variant="body2" className="cursor-pointer" fontWeight="600px" fontSize="10px" color="red">
                {headshotErr && <FormHelperText error>{headshotErr}</FormHelperText>}
              </Typography>
            </Box>
            <Typography fontSize="10px" fontWeight="600" sx={{ color: '#999999' }} mt={0.5}>
              jpg, png (max size {imgSize.limitText}, ideal dimensions 800x800 px)
            </Typography>
          </Box>
        </Box>

        {/* Profile information fields */}
        <Box mt={2}>
          <Grid container spacing={2}>
            {/* First name */}
            <Grid item xs={12} sm={6}>
              <InputLabel>First name *</InputLabel>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    required
                    name="firstName"
                    value={value}
                    id="firstName"
                    type="text"
                    placeholder="John"
                    variant="outlined"
                    onChange={onChange}
                    error={checkError('firstName')}
                    helperText={getError('firstName')?.toString()}
                  />
                )}
              />
            </Grid>

            {/* Last name */}
            <Grid item xs={12} sm={6}>
              <InputLabel>Last name *</InputLabel>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    size="small"
                    name="lastName"
                    value={value}
                    type="text"
                    id="lastName"
                    placeholder="Smith"
                    variant="outlined"
                    style={{ width: '100%', fontWeight: '600' }}
                    onChange={onChange}
                    error={checkError('lastName')}
                    helperText={getError('lastName')?.toString()}
                  />
                )}
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12} sm={isOrgSettings ? 6 : 12}>
              <InputLabel>Email *</InputLabel>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    size="small"
                    name="email"
                    // value={value}
                    id="email"
                    type="email"
                    placeholder="john@gmail.com"
                    defaultValue={getUserDetails().email}
                    variant="outlined"
                    style={{
                      width: '100%',
                      background: '#EFF0F4',
                      padding: '14.5px !important'
                    }}
                    onChange={onChange}
                    error={checkError('email')}
                    helperText={getError('email')?.toString()}
                    disabled
                  />
                )}
              />
            </Grid>

            {/* Time Zone */}
            {isOrgSettings && (
              <Grid item xs={12} sm={6}>
                <InputLabel>Time Zone *</InputLabel>
                <FormControl fullWidth>
                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      const defaultValue = value || defaultTz;
                      if (!value && defaultValue) {
                        onChange(defaultValue);
                      }
                      return (
                        <Autocomplete
                          fullWidth
                          size="small"
                          id="timeZone"
                          options={timezones}
                          getOptionLabel={(option) => option?.timezoneDisplayNames}
                          onChange={(_, option) => onChange(option ? option.value : '')}
                          value={timezones.find((option) => option.value === defaultValue) || null}
                          autoHighlight
                          renderOption={(props, option) => {
                            return (
                              <Box component="li" {...props}>
                                {option.timezoneDisplayNames}
                              </Box>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              placeholder="Choose Timezone"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'timezone'
                              }}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  <FormHelperText error>{getError('timezone')?.toString()}</FormHelperText>
                </FormControl>
              </Grid>
            )}

            {/* Member type */}
            {optionalFields.category && (
              <Grid item xs={12} sm={6}>
                <InputLabel>Member type *</InputLabel>
                <FormControl fullWidth>
                  <Controller
                    name="category"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Autocomplete
                          fullWidth
                          size="small"
                          id="userType"
                          options={
                            [...userTypes]?.sort((a: any, b: any) => {
                              const labelA = a.toLowerCase();
                              const labelB = b.toLowerCase();
                              if (labelA < labelB) {
                                return -1;
                              }
                              if (labelA > labelB) {
                                return 1;
                              }
                              return 0;
                            }) || []
                          }
                          getOptionLabel={(option) => categoryLabelFormatter(option)}
                          onChange={(_, option) => onChange(option ? option : '')}
                          value={value || null}
                          autoHighlight
                          isOptionEqualToValue={(option, value) => option === value || value === ''}
                          renderOption={(props, option) => {
                            return (
                              <Box component="li" {...props}>
                                {categoryLabelFormatter(option)}
                              </Box>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              placeholder="Choose member type"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'category'
                              }}
                            />
                          )}
                        />
                      );
                    }}
                  />
                  <FormHelperText error>{getError('category')?.toString()}</FormHelperText>
                </FormControl>
              </Grid>
            )}

            {/* Program role */}
            {/* <Grid item xs={12} sm={6}>
              <InputLabel>Program role *</InputLabel>
              <FormControl fullWidth>
                <Controller
                  name="programRole"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        fullWidth
                        size="small"
                        id="programRole"
                        options={programRole}
                        getOptionLabel={(option) => option} 
                        onChange={(_, option) => onChange(option ? option : '')}
                        value={value || null}
                        autoHighlight
                        isOptionEqualToValue={(option, value) => option === value || value === ''}
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="Choose Program role"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid> */}

            {mandatoryFields.map((item) => item.component)}
            {includedFields.map((item) => item.component)}
            {!ftue && optionalFieldsArray.map((item) => item.component)}

            {/* Phone number */}
            {isOrgSettings && (
              <Grid item xs={12} sm={6}>
                <PhoneInput
                  countryCodeArray={countryCodeArray}
                  data={data}
                  control={control}
                  errors={errors}
                  onChange={handleCountryChange}
                  name="phoneNumber"
                  showMark={false}
                />
              </Grid>
            )}

            {/* Bio */}
            {isOrgSettings && (
              <Grid item xs={12} sm={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <InputLabel>Bio </InputLabel>
                  </Grid>
                  <Grid item>
                    <InputLabel>{bioTextCount || userBioTextCount}/2000</InputLabel>
                  </Grid>
                </Grid>

                <Controller
                  name="bio"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      required
                      name="bio"
                      value={value}
                      id="bio"
                      type="text"
                      multiline
                      maxRows={4}
                      rows={4}
                      inputProps={{
                        maxLength: 2000
                      }}
                      placeholder="Write a little bit about yourself, your educational, and professional experience..."
                      onChange={(event) => {
                        onChange();
                        setBioTextCount(event.target.value?.split('')?.length);
                        setBioText(event.target.value);
                      }}
                      error={checkError('bio')}
                      helperText={getError('bio')?.toString()}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      {/* Dialog actions */}
      {getDialogActions()}
    </>
  );
};

export default EditProfileForm;
